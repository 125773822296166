@font-face {
  font-family: 'Montserrat';
  font-style: medium;
  font-weight: 500;
  src: local('Montserrat'),
    url('./assets/fonts/Montserrat-Medium.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat'),
    url('./assets/fonts/Montserrat-Regular.ttf') format('truetype');
}

@font-face {
  font-family: 'Montserrat';
  font-style: black;
  font-weight: 800;
  src: local('Montserrat'),
    url('./assets/fonts/Montserrat-ExtraBold.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-style: medium;
  font-weight: 400;
  src: local('Gotham'),
    url('./assets/fonts/GothamMedium.ttf') format('truetype');
}

@font-face {
  font-family: 'Gotham';
  font-style: normal;
  font-weight: 400;
  src: local('Gotham'), url('./assets/fonts/GothamBook.ttf') format('truetype');
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e5e5e5;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  box-sizing: border-box;
  margin: 0;
  font-family: 'Rubik', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
    'Oxygen', 'Ubuntu';
}

*:focus {
  outline: none;
}

img {
  vertical-align: bottom;
}

.onHover {
  margin-right: 11px;
}
